




























































import { Vue, Component, Prop } from "vue-property-decorator";
import Token from "@/models/interfaces/Token";
import { Hyperlinks } from "@/models/data/LinkDirectory";
import Halfmoon from "@/helpers/Halfmoon";
import ModalID from "@/models/data/ModalID";

@Component
export default class EmbedToken extends Vue {
  @Prop({ required: true }) readonly token!: Token;

  // indicates whether to show the information about tokens
  showInfo = false;

  /** get the link to trackgit landing site */
  get trackgitLink(): string {
    return Hyperlinks.landing;
  }

  /** Get the embed token modal ID */
  get embedModalId(): string {
    return ModalID.embedToken;
  }

  /**
   * Copy text to keyboard
   * @param elem The button element which triggers the event
   * @param containerId The container Id whose text to copy
   */
  copyCode(elem: HTMLButtonElement, containerId: string) {
    // Copy code to clipboard
    const range = document.createRange();
    range.selectNode(document.getElementById(containerId) as HTMLDivElement);
    window.getSelection()?.removeAllRanges(); // clear current selection
    window.getSelection()?.addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection()?.removeAllRanges(); // to deselect

    // Show confirmation
    elem.innerHTML =
      "<i class='fa fa-check-circle' aria-hidden='true'></i>&nbsp;Copied!";

    // Hide confirmation
    setTimeout(function() {
      elem.innerHTML =
        "<i class='fa fa-clipboard' aria-hidden='true'></i>&nbsp;Copy";
    }, 2000);
  }

  /** Close the embed token modal */
  closeEmbedModal() {
    Halfmoon.toggleModal(ModalID.embedToken);
  }
}
