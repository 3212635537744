

















































import { Vue, Component } from "vue-property-decorator";
import UserModule from "@/store/modules/UserModule";
import { Hyperlinks } from "@/models/data/LinkDirectory";
import UserHelper from "@/helpers/UserHelper";
import Halfmoon, {
  HalfmoonAlertType,
  HalfmoonFillType
} from "@/helpers/Halfmoon";
import Spinner from "@/components/misc/spinner.vue";
import SidebarModule from "@/store/modules/SidebarModule";

@Component({ components: { Spinner } })
export default class UserInfoNavbar extends Vue {
  loading = false;
  defaultAvatar = "https://avatars.dicebear.com/api/jdenticon/.svg?r=50&m=17";

  /** Returns the user's profile photo url */
  get userProfilePhoto(): string {
    if (!UserModule.user || !UserModule.user.photoURL) {
      return this.defaultAvatar;
    }
    return UserModule.user.photoURL;
  }

  /** Checks whether the user is logged in */
  get isUserAuthenticated(): boolean {
    return UserModule.isUserAuthenticated ? true : false;
  }

  /** Returns the current user */
  get user(): firebase.User | null {
    return UserModule.user;
  }

  /** Sign out the currently logged in user */
  async signOut() {
    if (this.loading) {
      return;
    }

    // closes the dropdown
    (this.$refs.userImageButton as HTMLButtonElement).click();

    this.loading = true;
    const actionStatus = await UserHelper.signOut();

    if (actionStatus.isSuccessful) {
      this.$router.replace({ path: Hyperlinks.login });
    } else {
      Halfmoon.toast({
        content: "Couldn't sign you out. Please try again.",
        alertType: HalfmoonAlertType.danger,
        fillType: HalfmoonFillType.filled
      });
    }
    this.loading = false;
  }

  /** Open account settings page */
  openAccountSettings() {
    this.$router.push({ path: Hyperlinks.accountSettings });
    SidebarModule.updateSidebarVisibility(false);
    // closes the dropdown
    (this.$refs.userImageButton as HTMLButtonElement).click();
  }
}
