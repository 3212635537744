



















import { Vue, Component } from "vue-property-decorator";
import HalmoonModule from "@/store/modules/HalmoonModule";
import Halfmoon from "@/helpers/Halfmoon";
import ModalID from "@/models/data/ModalID";
import CookieNames from "@/models/data/CookieNames";

@Component
export default class NoTokens extends Vue {
  /** gets whether app is in dark mode */
  get isDarkModeEnabled(): boolean {
    return HalmoonModule.isDarkMode;
  }

  /** gets whether the user created a token before */
  get createdTokenBefore(): boolean {
    return Vue.$cookies.isKey(CookieNames.activeTokenId);
  }

  /** Open create token modal */
  openCreateTokenModal() {
    Halfmoon.toggleModal(ModalID.createToken);
  }
}
