
















































import { Vue, Component } from "vue-property-decorator";
import ModalID from "@/models/data/ModalID";
import { Hyperlinks } from "@/models/data/LinkDirectory";
import UserHelper from "@/helpers/UserHelper";
import Halfmoon from "@/helpers/Halfmoon";

@Component
export default class ForgotPasswordForm extends Vue {
  loading = false;
  email = "";
  recoverySent = false;

  /** Gets the modal ID for forgot password modal */
  get forgotPasswordModalId(): string {
    return ModalID.forgotPassword;
  }

  /** Get hyperlink to login page */
  get loginLink(): string {
    return Hyperlinks.login;
  }

  /** Get hyperlink to register page */
  get registerLink(): string {
    return Hyperlinks.register;
  }

  mounted() {
    Halfmoon.toggleModal(ModalID.forgotPassword);
  }

  /** Sends out password recovery link to email */
  async sendPasswordRecoveryLink() {
    if (!this.recoverySent && !this.loading) {
      this.loading = true;
      await UserHelper.sendPasswordRecovery(this.email);
      this.loading = false;
      this.recoverySent = true;
      this.email = "";
    }
  }
}
