








































































import { Vue, Component, Watch } from "vue-property-decorator";
import ModalID from "@/models/data/ModalID";
import UserHelper from "@/helpers/UserHelper";
import { Hyperlinks } from "@/models/data/LinkDirectory";
import UserModule from "@/store/modules/UserModule";
import EmailMode from "@/models/data/EmailMode.ts";
import BodyMetaHelper from "@/helpers/BodyMetaHelper.ts";
import PageMeta from "@/models/data/PageMeta";
import Halfmoon from "@/helpers/Halfmoon";

@Component
export default class ResetPasswordForm extends Vue {
  loading = true;

  userEmail = "";
  recoveryCode = "";
  newPassword = "";

  errorMessage = "";

  passwordResetSuccessful = false;

  /** Gets the current auth status of the user */
  get isUserAuthenticated() {
    return UserModule.isUserAuthenticated;
  }

  /** Get the reset password modal's ID */
  get resetPasswordModalId(): string {
    return ModalID.resetPassword;
  }

  /** Get hyperlink to login page */
  get loginLink(): string {
    return Hyperlinks.login;
  }

  /** Get hyperlink to register page */
  get registerLink(): string {
    return Hyperlinks.register;
  }

  /** Hyperlink to password recovery page */
  get forgotPasswordLink() {
    return Hyperlinks.forgotPassword;
  }

  mounted() {
    Halfmoon.toggleModal(ModalID.resetPassword);
    this.verifyUrlCode();
  }

  /** Verify recovery code and initialize component with data */
  @Watch("isUserAuthenticated")
  async verifyUrlCode() {
    const mode = this.$route.query.mode;
    if (mode !== EmailMode.resetPassword) {
      return;
    }

    BodyMetaHelper.setDocumentTitle(PageMeta.ResetPassword.title);
    BodyMetaHelper.addMetaInfo(PageMeta.ResetPassword.metaTags);

    this.loading = true;

    let isCodeValid = false;
    const urlCode = this.$route.query.oobCode;
    if (typeof urlCode === "string") {
      const userEmail = await UserHelper.verifyRecoveryCode(urlCode);
      if (userEmail != null) {
        this.userEmail = userEmail;
        isCodeValid = true;
        this.recoveryCode = urlCode;
      }
    }

    if (!isCodeValid) {
      this.errorMessage = "The reset code has expired or is invalid. ";
    }

    this.loading = false;
  }

  /** Assign new password to user and redirect to login */
  async resetPassword() {
    if (!this.loading && this.recoveryCode.length > 0) {
      this.loading = true;

      const actionStatus = await UserHelper.confirmPasswordReset(
        this.recoveryCode,
        this.newPassword
      );
      this.passwordResetSuccessful = actionStatus.isSuccessful;

      if (actionStatus.isSuccessful) {
        this.$router.replace({ path: Hyperlinks.login });
        this.$router.go(0);
      } else {
        this.errorMessage = actionStatus.message as string;
      }
      this.loading = false;
    }
  }
}
