

















import { Vue, Component, Prop } from "vue-property-decorator";
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import HalmoonModule from "@/store/modules/HalmoonModule";

Vue.component("Skeleton", Skeleton);
Vue.component("SkeletonTheme", SkeletonTheme);

@Component
export default class SkeletonLoader extends Vue {
  @Prop({ default: 1 }) readonly count!: number;
  @Prop({ default: 1 }) readonly duration!: number;
  @Prop({ default: undefined }) readonly width!: string;
  @Prop({ default: undefined }) readonly height!: string;
  @Prop({ default: false }) readonly circle!: boolean;
  @Prop({ default: undefined }) readonly loading!: boolean;

  get isDarkModeOn(): boolean {
    return HalmoonModule.isDarkMode;
  }
}
