

































import { Vue, Component, Watch } from "vue-property-decorator";
import Navbar from "@/components/navbar/navbar.vue";
import Sidebar from "@/components/sidebar/sidebar.vue";
import SidebarModule from "@/store/modules/SidebarModule.ts";
import TokenModule from "@/store/modules/TokenModule.ts";
import LoginForm from "@/components/forms/login.vue";
import RegisterForm from "@/components/forms/register.vue";
import UserModule from "@/store/modules/UserModule";
import CreateToken from "@/components/forms/create-token.vue";
import TokenDetails from "@/components/token-details/token-details.vue";
import EmbedToken from "@/components/embed-token/embed-token.vue";
import ForgotPasswordForm from "@/components/forms/forgot-password.vue";
import ResetPasswordForm from "@/components/forms/reset-password.vue";
import { Hyperlinks } from "@/models/data/LinkDirectory";
import Halfmoon from "@/helpers/Halfmoon.ts";
import UserHelper from "@/helpers/UserHelper";
import EmailVerificationForm from "@/components/forms/email-verification.vue";
import EmailMode from "@/models/data/EmailMode";
import FirebaseModule from "@/store/modules/FirebaseModule";
import Token from "@/models/interfaces/Token";
import TokenHelper from "@/helpers/TokenHelper";
import OwnershipTransfer from "@/components/forms/ownership-transfer.vue";

require("halfmoon/css/halfmoon.min.css");

@Component({
  components: {
    Navbar,
    Sidebar,
    LoginForm,
    RegisterForm,
    ForgotPasswordForm,
    ResetPasswordForm,
    EmailVerificationForm,
    CreateToken,
    TokenDetails,
    EmbedToken,
    OwnershipTransfer
  }
})
export default class App extends Vue {
  showLogin = false;
  showRegister = false;
  showForgotPassword = false;
  showResetPassword = false;
  showEmailVerification = false;

  /** Gets the current auth status of the user */
  get isUserAuthenticated() {
    return UserModule.isUserAuthenticated;
  }

  /** Gets the currently selected token from TokenModule */
  get activeToken(): Token | null {
    return TokenModule.activeToken;
  }

  /** Get logged in user's data */
  @Watch("isUserAuthenticated")
  async getUserData(isAuthenticated: boolean) {
    if (isAuthenticated) {
      await this.fetchAllTokens();
    }
  }

  mounted() {
    Halfmoon.init();
    this.fixViewport();

    FirebaseModule.initializeApp();
  }

  /** Trigger event to get all tokens for user */
  async fetchAllTokens() {
    await TokenModule.fetchAllTokens();
  }

  /** Show authentication prompts if user is not logged in */
  @Watch("isUserAuthenticated")
  async setAuthPrompts(isUserAuthenticated: boolean) {
    const currentPath = this.$router.currentRoute.path;
    if (isUserAuthenticated === false) {
      TokenHelper.resetData();

      if (currentPath === "/") {
        if (UserHelper.isFirstTime()) {
          this.showRegister = true;
        } else {
          this.showLogin = true;
        }
        return;
      } else {
        this.showLogin = currentPath === Hyperlinks.login;
        this.showRegister = currentPath === Hyperlinks.register;
        this.showForgotPassword = currentPath === Hyperlinks.forgotPassword;

        if (currentPath === Hyperlinks.emailReferrer) {
          const emailMode = this.$route.query.mode;
          this.showResetPassword = emailMode === EmailMode.resetPassword;
        }
      }
      if (
        !this.showLogin &&
        !this.showRegister &&
        !this.showForgotPassword &&
        !this.showResetPassword &&
        !this.showEmailVerification
      ) {
        this.showLogin = true;
      }
    } else if (
      isUserAuthenticated &&
      currentPath === Hyperlinks.emailReferrer
    ) {
      this.showEmailVerification =
        this.$route.query.mode === EmailMode.verifyEmail;
    }
  }

  /** Hide the sidebar if it is open */
  closeSidebar() {
    if (SidebarModule.isOpen) {
      SidebarModule.updateSidebarVisibility(false);
    }
  }

  /**
   * Fix the height and width of the viewport.
   * This ensures that all elements display properly
   * even when the soft keyboard is being used.
   */
  fixViewport() {
    const viewheight = window.outerHeight;
    const viewwidth = window.outerWidth;
    const viewport = document.querySelector("meta[name=viewport]");
    viewport?.setAttribute(
      "content",
      "height=" + viewheight + ", width=" + viewwidth + ", initial-scale=1.0"
    );
  }
}
