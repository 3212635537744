













































































































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import Token from "@/models/interfaces/Token";
import NumberHelper from "@/helpers/NumberHelper.ts";
import TokenHelper from "@/helpers/TokenHelper";
import TokenGraph from "@/components/token-graph/token-graph.vue";
import Halfmoon from "@/helpers/Halfmoon";
import TokenSpec from "@/models/data/TokenSpec";
import ModalID from "@/models/data/ModalID.ts";

@Component({ components: { TokenGraph } })
export default class TokenDetails extends Vue {
  @Prop({ required: true }) readonly token!: Token;

  loading = true;

  tokenName = "";

  /** Gets the total number of views for the last 24 hours */
  get viewsLastDay(): string {
    const oneDayInMs = 24 * 60 * 60 * 1000;
    const timeLogsLastDay = TokenHelper.getTimeLogs(
      this.token,
      Date.now() - oneDayInMs,
      Date.now()
    );
    return NumberHelper.abbreviate(timeLogsLastDay.length);
  }

  /** Gets the total number of views for the last 7 days */
  get viewsLastWeek(): string {
    const oneWeekInMs = 24 * 60 * 60 * 1000 * 7;
    const timeLogsLastWeek = TokenHelper.getTimeLogs(
      this.token,
      Date.now() - oneWeekInMs,
      Date.now()
    );
    return NumberHelper.abbreviate(timeLogsLastWeek.length);
  }

  /** Gets the all time total number of views */
  get viewsAllTime(): string {
    const timeLogsAllTime = TokenHelper.getTimeLogs(
      this.token,
      -Infinity,
      Date.now()
    );
    return NumberHelper.abbreviate(timeLogsAllTime.length);
  }

  /** Maximum allowed length of token names */
  get maxTokenNameSize(): number {
    return TokenSpec.maxTokenNameSize;
  }

  /** Minimum allowed length of token names */
  get minTokenNameSize(): number {
    return TokenSpec.minTokenNameSize;
  }

  @Watch("token", { immediate: true, deep: true })
  ontokenChanged() {
    if (this.token != null) {
      document.title = `Dashboard - ${this.token.name}`;
      this.tokenName = this.token.name;
      this.loading = false;
    } else {
      this.loading = true;
    }
  }

  /** Change the token name in firestore. Updates token with this.tokenName  */
  async changeTokenName() {
    const tokenNameInput = this.$refs.tokenNameInput as HTMLImageElement;
    this.tokenName = this.tokenName.trim();

    if (
      this.tokenName.length === 0 ||
      this.token == null ||
      this.tokenName === this.token.name
    ) {
      this.tokenName = this.token?.name as string;
      return;
    }

    if (
      this.tokenName.length >= this.minTokenNameSize &&
      this.tokenName.length <= this.maxTokenNameSize
    ) {
      tokenNameInput.classList.remove("is-invalid");
      await TokenHelper.changeTokenName(this.token, this.tokenName);

      Halfmoon.toastSuccess({ content: "Token name changed successfully!" });
    } else {
      tokenNameInput.classList.add("is-invalid");
    }
  }

  /** Delete the current token */
  async deleteToken() {
    if (this.token != null) {
      // this closes the delete button's "confirm" dropdown
      (this.$refs.deleteTokenDropdown as HTMLButtonElement).classList.remove(
        "show"
      );

      this.loading = true;
      await TokenHelper.deleteToken(this.token);
      Halfmoon.toastSuccess({ content: "Token deleted successfully" });
      this.loading = false;
    }
  }

  /** Open the embed token modal */
  openEmbedModal() {
    Halfmoon.toggleModal(ModalID.embedToken);
  }

  /** Open ownership transfer modal */
  openOwnershipTransferModal() {
    Halfmoon.toggleModal(ModalID.ownershipTransfer);
  }
}
