const FirebaseConfig = {
  apiKey: "AIzaSyAXPI30pgCXF6k2aSPXh9cQvsAhiL_-5uY",
  authDomain: "trackgit-analytics.firebaseapp.com",
  databaseURL: "https://trackgit-analytics.firebaseio.com",
  projectId: "trackgit-analytics",
  storageBucket: "trackgit-analytics.appspot.com",
  messagingSenderId: "468746011004",
  appId: "1:468746011004:web:6d807a3a670575fd88e4f9",
  measurementId: "G-ZQ7MMQPDL0"
};

export default FirebaseConfig;
