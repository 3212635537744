






































































import { Vue, Component } from "vue-property-decorator";
import { Hyperlinks } from "@/models/data/LinkDirectory.ts";
import UserHelper from "@/helpers/UserHelper";
import ModalID from "@/models/data/ModalID";
import Halfmoon from "@/helpers/Halfmoon";
import GithubSignin from "@/components/forms/github-signin.vue";

@Component({ components: { GithubSignin } })
export default class LoginForm extends Vue {
  loading = false;
  redirectUrl = "/";

  email = "";
  password = "";
  errorMessage = "";

  /** Hyperlink to password recovery page */
  get forgotPasswordLink() {
    return Hyperlinks.forgotPassword;
  }

  /** Hyperlink to register page */
  get registerLink() {
    return Hyperlinks.register;
  }

  /** Get the login modal ID */
  get loginModalId(): string {
    return ModalID.login;
  }

  mounted() {
    Halfmoon.toggleModal(ModalID.login);
  }

  /** Attempt user login with email/password */
  async login() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    const loginStatus = await UserHelper.signIn(this.email, this.password);
    if (!loginStatus.isSuccessful) {
      this.errorMessage = "\nLogin attempt failed. Please try again.";
    } else {
      if (this.$router.currentRoute.path !== this.redirectUrl) {
        this.$router.replace({ path: this.redirectUrl });
      }
    }
    this.loading = false;
  }
}
