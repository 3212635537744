


















































import { Vue, Component } from "vue-property-decorator";
import SidebarModule from "@/store/modules/SidebarModule";
import UserInfoNavbar from "@/components/user-info-navbar/user-info-navbar.vue";
import HalfmoonModule from "@/store/modules/HalmoonModule.ts";
import { Emails } from "@/models/data/LinkDirectory";

@Component({ components: { UserInfoNavbar } })
export default class Navbar extends Vue {
  /** get trackgit support email address */
  get supportEmail(): string {
    return Emails.support;
  }

  /** Get the current calendar year */
  get yearNow(): number {
    const date = new Date();
    return date.getFullYear();
  }

  toggleSidebar() {
    SidebarModule.updateSidebarVisibility(!SidebarModule.isOpen);
  }

  toggleDarkMode() {
    HalfmoonModule.toggleDarkMode();
  }
}
