


















































import { Vue, Component } from "vue-property-decorator";
import Halfmoon from "@/helpers/Halfmoon";
import TokenModule from "@/store/modules/TokenModule";
import TokenSpec from "@/models/data/TokenSpec";
import ModalID from "@/models/data/ModalID";
import TokenHelper from "@/helpers/TokenHelper";

@Component
export default class CreateToken extends Vue {
  tokenName = "";
  loading = false;

  /** Close the create token modal */
  toggleCreateTokenModal() {
    Halfmoon.toggleModal(ModalID.createToken);
  }

  /** Maximum allowed length of token names */
  get maxTokenNameSize(): number {
    return TokenSpec.maxTokenNameSize;
  }

  /** Get the ID for create token modal */
  get createTokenModalId(): string {
    return ModalID.createToken;
  }

  /** Triggers event in TokenModule to create a new token */
  async createToken() {
    this.loading = true;
    const newTokenId = await TokenModule.createToken(this.tokenName);
    const newToken = TokenModule.tokens?.find(token => token.id === newTokenId);
    TokenModule.updateActiveToken(newToken);
    TokenHelper.updateTokenRoute(newToken);
    this.loading = false;

    this.tokenName = "";

    // close modal once process is complete
    this.toggleCreateTokenModal();

    // open embed token modal
    Halfmoon.toggleModal(ModalID.embedToken);
  }
}
