





















































































import { Vue, Component } from "vue-property-decorator";
import { Hyperlinks } from "@/models/data/LinkDirectory.ts";
import UserHelper from "@/helpers/UserHelper";
import ModalID from "@/models/data/ModalID";
import Halfmoon from "@/helpers/Halfmoon";
import GithubSignin from "@/components/forms/github-signin.vue";

@Component({ components: { GithubSignin } })
export default class RegisterForm extends Vue {
  loading = false;
  redirectUrl = "/";

  name = "";
  email = "";
  password = "";
  errorMessage = "";

  /** Hyperlink to terms of service page */
  get termsLink() {
    return Hyperlinks.tos;
  }

  /** Hyperlink to login page */
  get loginLink() {
    return Hyperlinks.login;
  }

  /** Get the register modal ID */
  get registerModalId(): string {
    return ModalID.register;
  }

  mounted() {
    Halfmoon.toggleModal(ModalID.register);
  }

  async register() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    const loginStatus = await UserHelper.signUp(
      this.name,
      this.email,
      this.password
    );
    if (!loginStatus.isSuccessful) {
      this.errorMessage =
        "\nYour account couldn't be created. Please try again.";
    } else {
      if (this.$router.currentRoute.path !== this.redirectUrl) {
        this.$router.replace({ path: this.redirectUrl });
      }
    }
    this.loading = false;
  }
}
