





































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import ModalID from "@/models/data/ModalID";
import Token from "@/models/interfaces/Token";
import Halfmoon from "@/helpers/Halfmoon";
import TokenHelper from "@/helpers/TokenHelper";

@Component
export default class OwnershipTransfer extends Vue {
  @Prop({ required: true }) readonly token!: Token;

  loading = false;

  retypedTokenName = "";

  newOwnerEmail = "";

  /** Get ID for ownership transfer modal */
  get ownershipTrasnferModalId(): string {
    return ModalID.ownershipTransfer;
  }

  /** Close ownership transfer modal */
  closeOwnershipTokenModal() {
    Halfmoon.toggleModal(ModalID.ownershipTransfer);
  }

  @Watch("token")
  onTokenChanged() {
    this.newOwnerEmail = "";
    this.retypedTokenName = "";
  }

  /** Initiate the process to transfer token ownership */
  async startOwnershipTransfer() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    if (this.retypedTokenName !== this.token.name) {
      (this.$refs.tokenName as HTMLInputElement).classList.add("is-invalid");
      (this.$refs.tokenName as HTMLInputElement).focus();
      this.loading = false;
      return;
    } else {
      (this.$refs.tokenName as HTMLInputElement).classList.remove("is-invalid");
    }

    const actionStatus = await TokenHelper.transferOwnership(
      this.token.id,
      this.newOwnerEmail
    );

    if (actionStatus.isSuccessful) {
      Halfmoon.toastSuccess({
        content:
          actionStatus.message || "The token was transferred successfully"
      });

      this.closeOwnershipTokenModal();
    } else {
      Halfmoon.toastError({
        content: actionStatus.message || "The transfer could not be completed."
      });
    }

    this.loading = false;
  }
}
